var jstz = require("jstimezonedetect");

var EmailBox = {
  posted_email: "",
  input: null,
  button: null,
  error: null,

  init: function (input = null, button = null, error = null) {
    EmailBox.input = input || $("#email-field");
    EmailBox.button = button || $("#subscribe-button");
    EmailBox.error = error || $(".email-box #subscribe-button-error");

    EmailBox.error.style.display = "none";

    EmailBox.bindFields();
  },

  bindFields: function () {
    EmailBox.input.addEventListener("keyup", (e) => {
      if (e.keyCode == 13) {
        EmailBox.button.click();
      }
    });

    EmailBox.button.addEventListener("click", () => {
      SmartButton.disable(EmailBox.button);
      EmailBox.subscribe();
    });
  },

  show: function () {
    $$(".boxes").forEach((node) => {
      node.style.display = "none";
    });
    $(".email-box").style.display = null;
    $(".email-box").animateCSS("fadeIn");
  },

  trackingProperties: function () {
    return {
      step: EmailBox.trackingStep(),
    };
  },

  trackingStep: function () {
    return "email";
  },

  subscribe: function () {
    var ref_publication_slug = $("#ref_publication_slug").value;
    var ref = $("#ref").value;
    var conversion_data = conversion_tracker.getConversionTags();
    var verify_email = "true";

    if (EmailBox.posted_email == EmailBox.input.value) {
      // The person got a suggestion from Mailgun but insisted on submitting their initial email. In good faith, let's assume Mailgun wrongly flagged the email.
      verify_email = "false";
    }

    ajax
      .post(Routes.users_path(), {
        user: {
          email: EmailBox.input.value,
          time_zone: jstz.determine().name(),
        },
        verify_email: verify_email,
        ref_publication_slug: ref_publication_slug,
        ref: ref,
        conversion_data: conversion_data,
      })
      .then((response) => response.json())
      .then((data) => {
        conversion_tracker.clearConversionTags();
        PaywallManager.clearCookie();
        EmailBox.subscribed(data);

        data.followed_publications.forEach((followed_publication) => {
          $$(".publishers .publisher-button").forEach((node) => {
            if (node.getAttribute("data-id") == followed_publication) {
              node.setAttribute("data-following-enabled", "true");
              node.classList.add("following");
              node.innerText = "Following";
            }
          });
        });
      })
      .catch((response) =>
        response.json().then((data) => {
          EmailBox.error.style.display = null;

          if (data.error === "Email has already been taken") {
            EmailBox.error.innerHTML = `You already have an account. <a class="underline" href="/login?email=${encodeURIComponent(
              EmailBox.input.value
            )}">Log in</a>?`;
          } else if (data.suggestion != null) {
            EmailBox.posted_email = EmailBox.input.value;
            EmailBox.error.innerHTML =
              "Did you mean <a href='#' onclick='EmailBox.input.value = \"" +
              data.suggestion +
              "\"; return false;'>" +
              data.suggestion +
              "</a>?";
          } else if (data.error != null) {
            EmailBox.error.innerText = data.error;
          }

          SmartButton.enable(EmailBox.button);
        })
      );
  },

  subscribed: function (data) {
    EVERY.fb_track("Lead");
    if (typeof gtag === "function") {
      gtag("event", "conversion", {
        send_to: "AW-1056773772/kPNGCKnUk9YDEIyt9PcD",
      });
    }

    if (data.paid_acquisition == true) {
      // Switch steps 2 & 3 in the subscribe flow
      BoxNavigator.switchToPaidAcquisitionFlow();
      BoxNavigator.next();
    } else {
      user_id = data.user.id;
      user_email = data.user.email;
      EmailBox.error.style.display = "none";
      if ($(".payment-box")) {
        BoxNavigator.next();
        SmartButton.enable(EmailBox.button);
      } else {
        this.button.innerText = "Thank you!";
        this.button.disabled = true;
        window.location = "/subscribe";
      }
      // Update follow buttons now that we have a user id
      $$(".publisher-button").forEach((node) => {
        node.setAttribute("data-current-user-id", user_id);
      });
    }
  },
};

var PaymentBox = {
  posted_email: "",
  applePaymentRequest: null,
  applePaymentAvailable: false,

  init: function () {
    PaymentBox.bindFields();
  },

  bindFields: function () {
    var stripeScript = document.createElement("script");
    stripeScript.src = "https://js.stripe.com/v3/";
    stripeScript.addEventListener("load", () => {
      PaymentBox.initStripe();
    });
    document.body.appendChild(stripeScript);
  },

  show: function () {
    if ($$(".boxes")) {
      $$(".boxes").forEach((node) => {
        if (node) {
          node.style.display = "none";
        }
      });
    }

    if ($(".payment-box")) {
      $(".payment-box").style.display = null;
      $(".payment-box").animateCSS("fadeIn");
    } else {
      window.location = redirect_url;
    }
  },

  trackingProperties: function () {
    let properties = {
      step: PaymentBox.trackingStep(),
    };

    if (hash) {
      properties["hash"] = hash;
    }

    return properties;
  },

  trackingStep: function () {
    return "payment";
  },

  initStripe: function () {
    // Init variables
    var stripe = Stripe(stripe_publishable_key);
    var elements = stripe.elements();
    var style = {
      base: {
        color: "#222",
        fontSize: "16px",
        color: "white",
      },
    };
    var form = document.getElementById("payment-form");
    var card = elements.create("card", { style: style });
    const displayError = document.getElementById("card-errors");

    const token = redirect_url
      ? redirect_url.split("/payment/")[1] &&
        redirect_url.split("/payment/")[1].split("/")[0]
      : null;

    // Mount Stripe widget
    card.mount("#card-element");

    // Add listener for error messages
    card.addEventListener("change", ({ error }) => {
      if (error) {
        displayError.innerText = error.message;
      } else {
        displayError.innerText = "";
      }
    });

    // Add form callbacks
    form.addEventListener("submit", function (ev) {
      ev.preventDefault();
      PaymentBox.handlePayment(stripe, card);
    });

    // Apple Pay
    if ($("#payment-request-button")) {
      PaymentBox.applePaymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "$1 for 2 weeks, then $200 per year",
          amount: 100,
        },
      });
      var prButton = elements.create("paymentRequestButton", {
        paymentRequest: PaymentBox.applePaymentRequest,
      });

      // Check the availability of the Payment Request API first.
      PaymentBox.applePaymentRequest.canMakePayment().then(function (result) {
        if (result) {
          prButton.mount("#payment-request-button");
          $(".apple-pay-container").style.display = "block";
          if (enable_trial) $("#button-text").innerText = "Start a trial";
          PaymentBox.applePaymentAvailable = true;
          EVERY.track("Viewed wallet button");
        }
      });

      PaymentBox.applePaymentRequest.on("paymentmethod", function (event) {
        const displayError = document.getElementById("card-errors");
        const buttonText = document.getElementById("button-text");
        const loadingSpinner = document.getElementById("loading-spinner");
        const applePayContainer = document.querySelector(
          ".apple-pay-container"
        );
        const payButton = document.getElementById("pay-button");

        // Show loading state and disable regular payment
        payButton.disabled = true;
        payButton.classList.add("opacity-75");
        buttonText.innerText = "Processing payment...";
        loadingSpinner.classList.remove("hidden");

        const resetButtonState = () => {
          payButton.disabled = false;
          payButton.classList.remove("opacity-75");
          buttonText.innerText =
            user_plan === yearly_plan ? "Start a Trial" : "Subscribe";
          loadingSpinner.classList.add("hidden");
        };

        const handleError = (message) => {
          displayError.innerText =
            message.includes("DOCTYPE") || !message
              ? "Error occurred. Please try again or contact us at hello@every.to"
              : message;
          resetButtonState();
          event.complete("fail");
        };

        var conversion_data = conversion_tracker.getConversionTags();
        // Extract token from redirect_url if present
        const token = redirect_url
          ? redirect_url.split("/payment/")[1] &&
            redirect_url.split("/payment/")[1].split("/")[0]
          : null;

        ajax
          .post(Routes.subscriptions_path(), {
            hash: hash,
            token: token,
            payment_method: event.paymentMethod.id,
            plan: user_plan,
            coupon: coupon_id,
            type: "apple_pay",
            conversion_data: conversion_data,
            referral: window.Rewardful && window.Rewardful.referral,
            subscription_source: window.subscription_source || "every",
          })
          .then((response) => response.json())
          .then((data) => {
            if (data.error) {
              handleError(data.error);
              return;
            }

            conversion_tracker.clearConversionTags();
            PaywallManager.clearCookie();

            event.complete("success");
            EVERY.track("Paid account created", { period: data.plan.period });
            EVERY.ga_event(
              "every.to",
              "Paid account created",
              data.plan.period
            );
            PaymentBox.paymentSuccess(data);
          })
          .catch(function (error) {
            handleError(error.message);
          });
      });
    }
  },

  switch: function (period) {
    if (period == "monthly") {
      user_plan = monthly_plan;
      $("#button-text").innerText = "Subscribe";
      PaymentBox.applePaymentRequest.update({
        total: {
          label: "$20 per month",
          amount: 2000,
        },
      });
    } else {
      user_plan = yearly_plan;
      $("#button-text").innerText = window.enable_trial
        ? "Start a Trial"
        : "Subscribe";
      PaymentBox.applePaymentRequest.update({
        total: {
          label: window.enable_trial
            ? "$1 for 2 weeks, then $200 per year"
            : "$200 per year",
          amount: window.enable_trial ? 100 : 20000,
        },
      });
    }
  },

  handleFreeAccount: function () {
    ajax
      .post(Routes.subscriptions_path(), {
        hash: hash,
        payment_method: null,
      })
      .then((response) => response.json())
      .then((data) => {
        EVERY.track("Free account created");
        EVERY.ga_event("every.to", "Free account created");
        BoxNavigator.next();
      });
  },

  handlePayment: function (stripe, card) {
    const displayError = document.getElementById("card-errors");
    const buttonText = document.getElementById("button-text");
    const loadingSpinner = document.getElementById("loading-spinner");
    const applePayContainer = document.querySelector(".apple-pay-container");
    const payButton = document.getElementById("pay-button");

    // Extract token from redirect_url if present
    const token = redirect_url
      ? redirect_url.split("/payment/")[1] &&
        redirect_url.split("/payment/")[1].split("/")[0]
      : null;

    // Show loading state
    payButton.disabled = true;
    payButton.classList.add("opacity-75");
    buttonText.innerText = "Processing payment...";
    loadingSpinner.classList.remove("hidden");

    // Disable Apple Pay during processing
    if (applePayContainer) {
      applePayContainer.classList.add("opacity-50", "pointer-events-none");
    }

    const resetButtonState = () => {
      payButton.disabled = false;
      payButton.classList.remove("opacity-75");
      buttonText.innerText =
        user_plan === yearly_plan && window.enable_trial
          ? "Start a Trial"
          : "Subscribe";
      loadingSpinner.classList.add("hidden");
      if (applePayContainer) {
        applePayContainer.classList.remove("opacity-50", "pointer-events-none");
      }
    };

    const handleError = (message) => {
      displayError.innerText =
        message.includes("DOCTYPE") || !message
          ? "Error occurred. Please try again or contact us at hello@every.to"
          : message;
      resetButtonState();
    };

    stripe
      .createPaymentMethod({
        type: "card",
        card: card,
        billing_details: {
          email: user_email,
        },
      })
      .then(function (result) {
        if (result.error) {
          handleError(result.error.message);
        } else {
          var conversion_data = conversion_tracker.getConversionTags();
          ajax
            .post(Routes.subscriptions_path(), {
              hash: hash,
              token: token,
              payment_method: result.paymentMethod.id,
              plan: user_plan,
              coupon: coupon_id,
              conversion_data: conversion_data,
              referral: window.Rewardful && window.Rewardful.referral,
              subscription_source: window.subscription_source || "every",
            })
            .then((response) => response.json())
            .then((data) => {
              if (data.error) {
                handleError(data.error);
                return;
              }

              conversion_tracker.clearConversionTags();
              PaywallManager.clearCookie();
              const payment_intent = data.payment_intent;

              if (payment_intent) {
                const { client_secret, status } = payment_intent;

                if (status === "requires_action") {
                  stripe
                    .confirmCardPayment(client_secret)
                    .then(function (result) {
                      if (result.error) {
                        handleError(result.error.message);
                      } else {
                        ajax.patch(Routes.verify_subscriptions_path(), {
                          user_id: user_id,
                        });
                        EVERY.track("Paid account created", {
                          period: data.plan.period,
                        });
                        EVERY.ga_event(
                          "every.to",
                          "Paid account created",
                          data.plan.period
                        );
                        PaymentBox.paymentSuccess(data);
                      }
                    })
                    .catch(function (error) {
                      handleError(error.message);
                    });
                } else {
                  EVERY.track("Paid account created", {
                    period: data.plan.period,
                  });
                  EVERY.ga_event(
                    "every.to",
                    "Paid account created",
                    data.plan.period
                  );
                  PaymentBox.paymentSuccess(data);
                }
              }
            })
            .catch(function (error) {
              handleError(error.message);
            });
        }
      })
      .catch(function (error) {
        handleError(error.message);
      });
  },

  paymentSuccess: function (data) {
    if (user_plan == yearly_plan) {
      EVERY.fb_track("StartTrial", { value: "1.00", currency: "USD" });

      if (typeof window["posthog"] !== "undefined") {
        window.posthog.capture("start_trial");
      }
    } else if (user_plan == monthly_plan) {
      EVERY.fb_track("Subscribe", { value: "20.00", currency: "USD" });

      if (typeof window["posthog"] !== "undefined") {
        window.posthog.capture("subscribe");
      }
    }

    // Check if we need to redirect (product payment page) or continue with flow (regular subscription)
    if (redirect_url) {
      // Product payment page case - redirect with subscription info
      window.location =
        redirect_url +
        (redirect_url.includes("?") ? "&" : "?") +
        `stripe_subscription_id=${data.subscription.id}&stripe_customer_id=${data.subscription.customer}`;
    } else {
      // Regular subscription flow - continue to next step
      BoxNavigator.next();
    }
  },
};

var PublishersBox = {
  init: function () {
    let proceedFromPublishersButton = document.querySelector(
      ".publishers-box .proceed button"
    );
    let ckFormContainer = document.querySelector("#ck-form-container");

    const proceedFromPublishers = () => {
      if (ckFormContainer && ckFormContainer.querySelector("form")) {
        let ckForm = ckFormContainer.querySelector("form");
        let ckEmail = ckForm.querySelector("input[name='email_address']");
        let ckSubmitButton = ckForm.querySelector("button");
        ckEmail.value = user_email;
        document.body.addEventListener("ckjs:submission:complete", (e) =>
          BoxNavigator.next()
        );
        ckSubmitButton.click();
      } else {
        BoxNavigator.next();
      }
    };

    proceedFromPublishersButton.addEventListener(
      "click",
      proceedFromPublishers
    );
  },

  show: function () {
    $$(".boxes").forEach((node) => {
      node.style.display = "none";
    });
    $(".publishers-box").style.display = null;
    $(".publishers-box").animateCSS("fadeIn");
  },

  trackingProperties: function () {
    return {
      step: PublishersBox.trackingStep(),
    };
  },

  trackingStep: function () {
    return "publications";
  },
};

var SurveyBox = {
  init: function () {
    $("#submit-survey").addEventListener("click", (e) => {
      SurveyBox.submit(e.target);
    });
    $(".survey-box").style.display = "none";
  },

  show: function () {
    $$(".boxes").forEach((node) => {
      node.style.display = "none";
    });
    $(".survey-box").style.display = null;
    $(".survey-box").animateCSS("fadeIn");
  },

  trackingProperties: function () {
    return {
      step: SurveyBox.trackingStep(),
    };
  },

  trackingStep: function () {
    return "survey";
  },

  submit: function (button) {
    SmartButton.disable(button);
    const fname = $("#first-name").value;
    const lname = $("#last-name").value;
    const jobLevel = $("#job-level").value;
    const industry = $("#industry").value;
    const companyName = $("#company-name").value;
    const companySize = $("#company-size").value;
    const postalCode = $("#postal-code").value;
    const consultingInterest = $(
      "input[type='radio'][name=consulting-interest]:checked"
    ).value;
    const speakingInterest = $(
      "input[type='radio'][name=speaking-interest]:checked"
    ).value;
    const earlyAdopter = $(
      "input[type='radio'][name=early-adopter]:checked"
    ).value;

    if (postalCode && postalCode.toString().length < 5) {
      alert("Please, enter correct postal code or leave the field empty.");
      return;
    }

    ajax
      .post(Routes.surveys_path(), {
        survey: {
          fname,
          lname,
          jobLevel,
          industry,
          companyName,
          companySize,
          postalCode,
          consultingInterest,
          speakingInterest,
          earlyAdopter,
        },
      })
      .then((response) => response.json())
      .then((data) => {
        SurveyBox.submitted();
      });
  },

  submitted: function () {
    window.location = redirect_url;
  },
};

var BoxNavigator = {
  all: [EmailBox, PaymentBox, SurveyBox],
  _current: 0,

  init: function (box) {
    BoxNavigator._current = BoxNavigator.all.indexOf(box);

    box.show();
    BoxNavigator.track();
  },

  next: function () {
    BoxNavigator.increment();
    let box = BoxNavigator.all[BoxNavigator._current];

    box.show();
    BoxNavigator.track();
  },

  increment: function () {
    BoxNavigator._current = BoxNavigator._current + 1;
  },

  track: function () {
    let step_number = BoxNavigator._current + 1;
    let box = BoxNavigator.all[BoxNavigator._current];
    let properties = box.trackingProperties();
    let tracking_step = box.trackingStep();

    EVERY.track("Subscribe Flow - Step " + step_number, properties);
    EVERY.ga_event(
      "every.to",
      "Subscribe Flow - Step " + step_number,
      tracking_step
    );
    EVERY.ga_pageview(Routes.subscribe_path() + "/step-" + step_number);
  },

  switchToPaidAcquisitionFlow: function () {
    BoxNavigator.all = [EmailBox, PaymentBox, SurveyBox];
  },
};

const paymentPlanCardsContainerClickHandler = (e) => {
  let cardsContainer = e.currentTarget;

  if (e.target === cardsContainer)
    //when empty space between the cards is clicked do nothing
    return;

  let planCards = cardsContainer.querySelectorAll(".payment-plan-card");
  let planButtons = cardsContainer.querySelectorAll(".payment-plan-button");
  let clickedCard = e.target.closest(".payment-plan-button");

  //handle setting the green border and expanding the card for mobile UI
  planCards.forEach((card) => {
    if (card && card.dataset.planType === clickedCard.dataset.planType) {
      card.classList.add("selected");
    } else {
      card.classList.remove("selected");
    }
  });

  planButtons.forEach((card) => {
    if (card.dataset.planType === clickedCard.dataset.planType) {
      card.classList.add("selected");
    } else {
      card.classList.remove("selected");
    }
  });

  let planType = clickedCard.dataset.planType;

  if (planType !== "free") {
    //set plan type when the selected plan is not free
    PaymentBox.switch(planType);

    // toggle display of stripe widget and continue-free button
    $(".credit-card").style.display = "block";
    if (PaymentBox.applePaymentAvailable) {
      $(".apple-pay-container").style.display = "block";
    }
  } else {
    //when plan selected is free
    // toggle display of stripe widget and continue-free button
    $(".credit-card").style.display = "none";
    if (PaymentBox.applePaymentAvailable) {
      $(".apple-pay-container").style.display = "none";
    }
  }
};

window.addEventListener("DOMContentLoaded", (event) => {
  const paymentPlanCardsContainer = $("#payment-plan-cards-container");
  if (paymentPlanCardsContainer) {
    paymentPlanCardsContainer.addEventListener(
      "click",
      paymentPlanCardsContainerClickHandler
    );
    if ($(".continue-free-btn")) {
      $(".continue-free-btn").addEventListener(
        "click",
        PaymentBox.handleFreeAccount
      );
    }
  }
});

module.exports = {
  EmailBox: EmailBox,
  PaymentBox: PaymentBox,
  PublishersBox: PublishersBox,
  SurveyBox: SurveyBox,
  BoxNavigator: BoxNavigator,
};
